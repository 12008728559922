import React from 'react';

import { t } from '../i18n_helper';

export type Props = {
  open: boolean;
  onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
  titleKey: string;
  children?: React.ReactNode;
};

export const Modal: React.FC<Props> = ({
  open,
  titleKey,
  children,
  onClose,
}) => {
  if (!open) {
    return null;
  }

  return (
    <div className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-full text-center">
          <div className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 max-w-2xl w-full">
            <div className="bg-white p-6 pb-4">
              <div className="flex items-start">
                <div className="ml-4 text-left flex-1">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {t(titleKey)}
                  </h3>
                  <div className="mt-2">{children}</div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 py-3 px-6 flex flex-row-reverse">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown-500 ml-3 text-gray-500"
                onClick={onClose}
              >
                {t('close')}
              </button>
            </div>
            <button
              className="flex items-center px-1 hover:bg-gray-100 text-gray-700 absolute top-4 right-4"
              onClick={onClose}
            >
              <span className="material-symbols-outlined text-2xl">close</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
