import { Notifier } from '@airbrake/browser';

interface Window {
  airbrakeOptions: {
    projectId: number;
    projectKey: string;
    environment: string;
  };
}
declare let window: Window;

const { airbrakeOptions } = window;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let notifier: any = null;

if (airbrakeOptions.environment === 'production') {
  notifier = new Notifier(airbrakeOptions);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const notifyToAirbrake = (error: any, errorInfo: any) => {
  if (notifier === null) {
    return;
  }

  notifier.notify({
    error,
    params: {
      errorInfo,
    },
  });
};
