import React, { ReactNode } from 'react';

import { notifyToAirbrake } from '../error_notifier';

type Props = {
  children?: ReactNode;
  fallback?: ReactNode;
};

type State = {
  hasError: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
};

export class ErrorBoundary extends React.Component<Props> {
  state: State;

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentDidCatch(error: any, errorInfo: any) {
    notifyToAirbrake(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;

    if (hasError) {
      return (
        <div className="text-red-500 flex items-center mt-5">
          <span className="material-symbols-outlined text-2xl mr-1">
            warning
          </span>
          {error.message}
        </div>
      );
    }

    return this.props.children;
  }
}
