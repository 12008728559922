import React from 'react';

type Props = {
  isSplit: boolean;
  isPreview: boolean;
  isFullscreen: boolean;
  onChangeIsPreview: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeIsOpenBackupsModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeIsSplit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onChangeIsFullscreen: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const Buttons: React.FC<Props> = ({
  isPreview,
  isSplit,
  isFullscreen,
  onChangeIsPreview,
  onChangeIsOpenBackupsModal,
  onChangeIsSplit,
  onChangeIsFullscreen,
}) => {
  return (
    <div className="absolute -top-9 right-0 flex space-x-1">
      {!isSplit && (
        <button
          className="flex items-center px-1 hover:bg-gray-100 text-gray-700"
          onClick={onChangeIsPreview}
        >
          <span className="material-symbols-outlined text-2xl">
            {isPreview ? 'code' : 'visibility'}
          </span>
        </button>
      )}
      <button
        className="flex items-center px-1 hover:bg-gray-100 text-gray-700"
        onClick={onChangeIsOpenBackupsModal}
      >
        <span className="material-symbols-outlined text-2xl">history</span>
      </button>
      <button
        className="flex items-center px-1 hover:bg-gray-100 text-gray-700"
        onClick={onChangeIsSplit}
      >
        <span className="material-symbols-outlined text-2xl">
          {isSplit ? 'square' : 'vertical_split'}
        </span>
      </button>
      <button
        className="flex items-center px-1 hover:bg-gray-100 text-gray-700"
        onClick={onChangeIsFullscreen}
      >
        <span className="material-symbols-outlined text-2xl">
          {isFullscreen ? 'fullscreen_exit' : 'fullscreen'}
        </span>
      </button>
    </div>
  );
};
