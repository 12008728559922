import { Controller } from '@hotwired/stimulus';
import React from 'react';
import { createRoot } from 'react-dom/client';
import mermaid from 'mermaid';

import { ArticleEditor } from '../components/article_editor';
import { ErrorBoundary } from '../components/error_boundary';

// Connects to data-controller="article-editor"
export default class extends Controller {
  get props() {
    return JSON.parse(this.data.get('props') ?? '{}');
  }

  connect() {
    this.root = createRoot(this.element);
    this.root.render(
      <ErrorBoundary
        fallback={
          <span className="material-symbols-outlined text-2xl mt-5 text-red-500">
            warning
          </span>
        }
      >
        <ArticleEditor {...this.props} />
      </ErrorBoundary>,
    );
  }

  disconnect() {
    this.root?.unmount();
  }
}
