import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['source', 'copy', 'copied'];

  copy() {
    this.dispatch('copy', { detail: { content: this.sourceTarget.value } });
    this.sourceTarget.select();
    document.execCommand('copy');
    this.copyTarget.classList.add('hidden');
    this.copiedTarget.classList.remove('hidden');
    setTimeout(() => {
      this.copyTarget.classList.remove('hidden');
      this.copiedTarget.classList.add('hidden');
    }, 1000);
  }
}
