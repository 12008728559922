interface Window {
  I18n: {
    locale: string;
  };
}
declare let window: Window;

type Translations = {
  [key: string]: { [key: string]: string };
};

const translations: Translations = {
  ja: {
    close: '閉じる',
    backup: 'バックアップ',
    noBackups: 'バックアップがありません',
    content: '本文',
    restore: 'リストア',
  },
};

const { I18n } = window;

export const t = (key: string) => {
  return translations[I18n.locale][key];
};
