import React from 'react';

import { Modal, Props as ModalProps } from './modal';
import { loadBackups, Backup } from '../storage_helper';
import { t } from '../i18n_helper';

type Props = {
  onLoad: (content: string) => void;
} & Omit<ModalProps, 'titleKey' | 'children'>;

export const BackupsModal: React.FC<Props> = ({ open, onClose, onLoad }) => {
  const backups = React.useMemo(() => {
    return loadBackups();
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} titleKey="backup">
      <ul className="divide-y divide-gray-200">
        {backups.length < 1 && (
          <li className="py-6">
            <div className="text-gray-500">
              <h3>{t('noBackups')}</h3>
            </div>
          </li>
        )}
        {backups.map((backup: Backup) => (
          <li className="py-6" key={backup.storedAt}>
            <div className="flex flex-1 justify-between items-center">
              <div className="flex-1 font-medium text-gray-900 mr-3">
                <h3>{backup.storedAt}</h3>
                <p className="mt-1 text-sm text-gray-500">
                  <textarea
                    value={backup.content}
                    className="h-20 w-full text-gray-500 focus:outline-none border-0 bg-gray-100 rounded"
                    readOnly
                  />
                </p>
              </div>

              <div className="flex">
                <button
                  className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brown-500"
                  onClick={(e) => {
                    e.preventDefault();
                    onLoad(backup.content);
                  }}
                >
                  <span className="flex items-center">
                    <span className="material-symbols-outlined text-lg leading-3">
                      restore_page
                    </span>
                    <span className="ml-1">{t('restore')}</span>
                  </span>
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Modal>
  );
};
