export const loadIsSplit = () => localStorage.isSplit === 'true';
export const saveIsSplit = (isSplit: boolean) => {
  localStorage.isSplit = isSplit.toString();
};

export type Backup = {
  pathname: string;
  storedAt: string;
  content: string;
};

export const loadBackups = () => {
  return JSON.parse(localStorage.backups ?? '[]').filter((stored: Backup) => {
    return stored.pathname === location.pathname;
  });
};

export const saveBackup = (content: string) => {
  const date = new Date();
  const storedAt = [
    [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-'),
    [date.getHours(), date.getMinutes(), date.getSeconds()]
      .map((s) => `0${s}`.slice(-2))
      .join(':'),
  ].join(' ');

  const backup = {
    pathname: location.pathname,
    storedAt,
    content,
  };
  const backups = [
    backup,
    ...JSON.parse(localStorage.backups ?? '[]').slice(0, 9),
  ];
  localStorage.backups = JSON.stringify(backups);
};
