import React from 'react';
import { marked } from 'marked';
import mermaid from 'mermaid';
import debounce from 'lodash/debounce';

import { loadIsSplit, saveIsSplit, saveBackup } from '../../storage_helper';
import { BackupsModal } from '../backups_modal';
import { t } from '../../i18n_helper';
import { Buttons } from './buttons';

const debouncedSaveBackup = debounce(saveBackup, 3000);

type Props = {
  content: string;
  attachmentsPath: string;
  csrfToken: string;
};

export const ArticleEditor: React.FC<Props> = ({
  content: propsContent,
  attachmentsPath,
  csrfToken,
}) => {
  const [content, setContent] = React.useState(propsContent);
  const [isDragging, setIsDraggging] = React.useState(false);
  const [isPreview, setIsPreview] = React.useState(false);
  const [isSplit, setIsSplit] = React.useState(loadIsSplit());
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [isOpenBackupsModal, setIsOpenBackupsModal] = React.useState(false);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  const onChangeIsPreview = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setIsPreview(!isPreview);
    },
    [isPreview],
  );

  const onChangeIsSplit = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      const nextIsSplit = !isSplit;
      setIsSplit(nextIsSplit);
      saveIsSplit(nextIsSplit);
    },
    [isSplit],
  );

  const onChangeIsFullscreen = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setIsFullscreen(!isFullscreen);
    },
    [isFullscreen],
  );

  const onChangeIsOpenBackupsModal = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setIsOpenBackupsModal(!isOpenBackupsModal);
    },
    [isOpenBackupsModal],
  );

  const onLoadBackupContent = React.useCallback((nextContent: string) => {
    setContent(nextContent);
    setIsOpenBackupsModal(false);
  }, []);

  const onChangeContent = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const nextContent = e.target.value;
      debouncedSaveBackup(nextContent);
      setContent(nextContent);
    },
    [],
  );

  const onDragOver = React.useCallback(
    (e: React.DragEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
    },
    [],
  );

  const onDragEnter = React.useCallback(
    (e: React.DragEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      setIsDraggging(true);
    },
    [],
  );

  const onDragLeave = React.useCallback(
    (e: React.DragEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      setIsDraggging(false);
    },
    [],
  );

  const onDrop = React.useCallback(
    (e: React.DragEvent<HTMLTextAreaElement>) => {
      e.preventDefault();

      const formData = new FormData();
      formData.append('attachment[file]', e.dataTransfer.files[0]);

      fetch(attachmentsPath, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      })
        .then((response) => response.json())
        .then((result) => {
          const textarea = textareaRef.current;
          if (textarea) {
            const [start, end] = [
              textarea.selectionStart,
              textarea.selectionEnd,
            ];
            if (result.errors) {
              alert(result.errors.join('\n'));
            } else {
              textarea.setRangeText(`![](${result.path})`, start, end);
            }
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
    [],
  );

  const html = React.useMemo(() => {
    const renderer = new marked.Renderer();
    const defaultCode = renderer.code;
    renderer.code = (code, language, isEscaped) => {
      if (language === 'mermaid') {
        return `<div class="mermaid">${code}</div>`;
      } else {
        return defaultCode.call(renderer, code, language, isEscaped);
      }
    };
    const rendered = marked(content, {
      breaks: true,
      renderer,
    });
    return rendered;
  }, [content]);

  return (
    <>
      <div
        className={`
          ${
            isFullscreen
              ? 'absolute top-0 bottom-0 right-0 left-0 bg-white p-3'
              : 'mt-6'
          }
        `}
      >
        <label
          className="block text-sm font-medium text-gray-700"
          htmlFor="article_content"
        >
          {t('content')}
        </label>
        <BackupsModal
          open={isOpenBackupsModal}
          onClose={onChangeIsOpenBackupsModal}
          onLoad={onLoadBackupContent}
        />
        <div className="relative mt-2">
          <Buttons
            isSplit={isSplit}
            isPreview={isPreview}
            isFullscreen={isFullscreen}
            onChangeIsPreview={onChangeIsPreview}
            onChangeIsOpenBackupsModal={onChangeIsOpenBackupsModal}
            onChangeIsSplit={onChangeIsSplit}
            onChangeIsFullscreen={onChangeIsFullscreen}
          />
          <div className="h-[calc(100vh_-_4rem)] flex">
            {(!isPreview || isSplit) && (
              <textarea
                ref={textareaRef}
                id="article_content"
                name="article[content]"
                className={`${
                  isSplit ? 'flex-1 mr-1' : ''
                } shadow-sm focus:ring-brown-500 focus:border-brown-500 block w-full border border-gray-300 rounded-md ${
                  isDragging ? 'border-2' : ''
                }`}
                value={content}
                onChange={onChangeContent}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
              ></textarea>
            )}
            {(isPreview || isSplit) && (
              <>
                <div
                  className={`overflow-auto ${
                    isSplit ? 'flex-1 ml-1' : ''
                  } shadow-sm focus:ring-brown-500 focus:border-brown-500 block w-full border border-gray-300 rounded-md break-all min-h-56 prose max-w-none bg-gray-50 py-2 px-3`}
                  dangerouslySetInnerHTML={{ __html: html }}
                  ref={() => mermaid.init(undefined, '.mermaid')}
                ></div>
                <input type="hidden" name="article[content]" value={content} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
